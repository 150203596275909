
import { Vue, Options } from 'vue-class-component';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
  BPMLocation,
  Clinic,
  Consult,
  Patient,
  RejectReasonValue,
  ResolveReasonValue,
  Status,
  User
} from '@/models';
import {
  OrganisationLocationService,
  PatientFormSubmissionService
} from '@/services/api';
import OphthalmologyConsultAggregateService, {
  EpisodeOfCareStatusCounts,
  RequestParams
} from '@/services/aggregate/ophthalmology-consult.aggregate.service';
import {
  BaseButton,
  BaseIcon,
  BaseModal,
  BasePagination,
  BasePopover,
  BaseTextInput,
  DataTable,
  LockConsultModal,
  PopoverButton,
  PopoverLink,
  SpecialityButton,
  FilterPopover
} from '@/lib/components';
import ConsultStatusSelector from '@/lib/components/Select/ConsultStatusSelector.vue';
import axios, { CancelTokenSource } from 'axios';
import {
  getDobFromISOString,
  getNhsNumberFromPatient
} from '@/helpers/patient.helper';
import debounce from 'lodash-es/debounce';
import AssignConsultModal from '@/views/worklists/AssignConsultModal.vue';
import RejectedReasonModal from '@/lib/components/Modals/RejectedReasonModal.vue';
import RejectReasonModal from '@/lib/components/Modals/RejectReasonModal.vue';
import MoveBackToScheduledModal from '@/lib/components/Modals/MoveBackToScheduledModal.vue';
import {
  CodeableConcept,
  ConsultStatusSelectorOption,
  IColumn,
  IErrors,
  IFilterOptions,
  IFilterOptionsSelected
} from '@/lib';
import ReviewSummary from '@/lib/components/PatientSummary/ReviewSummary.vue';
import { isFeatureFlagEnabled } from '@/helpers/feature-flag.helper';
import {
  FEATURES,
  sexOptions,
  genderOptions,
  codeableConceptCategories
} from '@/constants';
import { TranslateResult } from 'vue-i18n/index';
import ResolveReasonModal from '@/lib/components/Modals/ResolveReasonModal.vue';
import { EpisodeOfCareService } from '@/services/api/episode-of-care.service';
import { CodeableConceptService } from '@/services/api/codeable-concept.service';
import { EncounterService } from '@/services/api/encounter.service';
import { ScheduleValue } from '@/models/episode-of-care/encounter.model';
import OphthalmologyConsultViewModel from '@/models/ophthalmology-consult.view.model';
import { findCodeableConceptByCode } from '@/helpers/codeableConcepts.helper';
import { useProgressStore } from '@/stores/progress.store';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';
import { EpisodeOfCareResponseData } from '@/models/episode-of-care/episode-of-care.model';

dayjs.extend(relativeTime);

@Options({
  components: {
    ResolveReasonModal,
    ReviewSummary,
    MoveBackToScheduledModal,
    RejectReasonModal,
    RejectedReasonModal,
    AssignConsultModal,
    ConsultStatusSelector,
    LockConsultModal,
    PopoverButton,
    BaseIcon,
    BaseButton,
    PopoverLink,
    SpecialityButton,
    BasePopover,
    BasePagination,
    BaseTextInput,
    DataTable,
    BaseModal,
    FilterPopover
  },
  props: {
    organisationId: {
      type: String,
      required: true
    }
  }
})
export default class ConsultListPage extends Vue {
  organisationId!: string;
  scheduleErrors: Record<string, string[]> = {};
  unWatchRoute = null;
  progressStore = useProgressStore();
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();
  allConsultsFirstLoadingIsDone = false;
  loading = true;

  showFilter = false;
  selectedStatusIndex = 0;

  columns: IColumn[] = [];
  rows: Consult[] = [];
  perPage = 0;
  search = '';
  encounterService = new EncounterService();

  ophthalmologyConsultService = new OphthalmologyConsultAggregateService(
    new EpisodeOfCareService(),
    new CodeableConceptService(),
    new EncounterService()
  );

  ophthalmologyConsults: OphthalmologyConsultViewModel[] = [];

  request: CancelTokenSource | null = null;
  rejectReasonError: string | null = null;
  resolveReasonError: string | null = null;

  // Consult action
  selectedConsultId = '';
  scheduleConsultModalVisible = false;
  enableRejectReasonModal = false;
  enableMoveBackToScheduledModal = false;
  enableResolveReasonModal = false;
  enableLockConsultModal = false;
  viewAndCopyReviewSummary = false;

  modalTargetLockedByUserFullName = '';
  modalTargetReviewId = '';
  modalTargetClinic: Clinic | null = null;
  modalTargetConsultCompletedAt = '';
  modalTargetFormSubmissionId = '';
  modalTargetFormSubmissionClinician = '';
  status: ConsultStatusSelectorOption[] = [];

  locations: BPMLocation[] = [];
  filteredLocations?: IFilterOptionsSelected['checkboxItems'];

  sexOptions = sexOptions();
  genderOptions = genderOptions();

  // Reject
  currentConsult: Partial<Consult> | null = null;
  selectedPatient: Patient | null = null;

  statuses: CodeableConcept[] = [];

  // encounterClasses
  encounterClasses: CodeableConcept[] = [];

  get locationService(): OrganisationLocationService {
    return new OrganisationLocationService(this.organisationId);
  }

  get currentUserId() {
    return this.sessionStore.currentUser.id;
  }

  get providerAddress() {
    return this.modalTargetClinic?.provider?.address?.formatted_address ?? '';
  }

  get page() {
    return Number(this.$route.query.page) || 1;
  }

  get sort() {
    return this.$route.query.sort || 'patients.last_name';
  }

  get selectedStatus() {
    return this.status[this.selectedStatusIndex].statuses;
  }

  get filterOptions(): IFilterOptions | null {
    const newItems =
      this.locations.length > 0
        ? this.locations.map((location) => ({
          label: location.name,
          value: location.id
        }))
        : null;

    return newItems
      ? {
        checkboxItems: [
          {
            label: this.$t(
              'custom.uhb.consult.filter.location.label'
            ) as string,
            options: newItems
          }
        ]
      }
      : null;
  }

  get updateSearchDebounced() {
    return debounce(() => this.updateSearch(), 500);
  }

  buildClinicTypeOptions(clinic: Clinic) {
    return {
      value: clinic.id,
      label: clinic.name
    };
  }

  buildFullName(user: User) {
    return `${user.family_name} ${user.given_name}`;
  }

  beforeCreate() {
    this.status = [
      {
        label: this.$t('custom.uhb.consult.tab-all-consults') as string,
        shorthand: this.$t(
          'custom.uhb.consult.tab-all-consults-shorthand'
        ) as string,
        statuses: [],
        columns: [
          // TODO: Might need to change this once design is ready
          {
            name: 'created_at',
            label: this.$t('custom.uhb.consult.created-date')
          },
          { name: 'last_name', label: this.$t('platform.patient.patient') },
          { name: 'patient_ids' },
          { name: 'clinic_name', label: this.$t('custom.uhb.consult.clinic') },
          { name: 'location', label: this.$t('custom.uhb.consult.site') },
          { name: 'status', label: this.$t('platform.common.status') },
          { name: 'actions', label: '', align: 'right' }
        ],
        rows: [],
        total: 0,
        paginationTotal: 0
      },
      {
        label: this.$t('custom.uhb.consult.tab-unscheduled') as string,
        statuses: ['Needs Scheduling'],
        columns: [
          {
            name: 'created_at',
            label: this.$t('custom.uhb.consult.created-date')
          },
          { name: 'last_name', label: this.$t('platform.patient.patient') },
          { name: 'patient_ids' },
          { name: 'clinic_name', label: this.$t('custom.uhb.consult.clinic') },
          { name: 'location', label: this.$t('custom.uhb.consult.site') },
          {
            name: 'consult_type',
            label: this.$t('custom.uhb.consult.consult-type')
          },
          { name: 'actions', label: '', align: 'right' }
        ],
        rows: [],
        total: 0,
        paginationTotal: 0
      },
      {
        label: this.$t('custom.uhb.consult.tab-scheduled') as string,
        statuses: ['Awaiting Consult'],
        columns: [
          {
            name: 'scheduled_at',
            label: this.$t('custom.uhb.consult.scheduled-date')
          },
          { name: 'last_name', label: this.$t('platform.patient.patient') },
          { name: 'patient_ids' },
          { name: 'clinic_name', label: this.$t('custom.uhb.consult.clinic') },
          { name: 'location', label: this.$t('custom.uhb.consult.site') },
          {
            name: 'consult_type',
            label: this.$t('custom.uhb.consult.consult-type')
          },
          { name: 'actions', label: '', align: 'right' }
        ],
        rows: [],
        total: 0,
        paginationTotal: 0
      },
      {
        label: this.$t('custom.uhb.consult.tab-consulting') as string,
        statuses: ['Consulting'],
        columns: [
          {
            name: 'scheduled_at',
            label: this.$t('custom.uhb.consult.scheduled-date')
          },
          { name: 'last_name', label: this.$t('platform.patient.patient') },
          { name: 'patient_ids' },
          { name: 'clinic_name', label: this.$t('custom.uhb.consult.clinic') },
          { name: 'location', label: this.$t('custom.uhb.consult.site') },
          { name: 'triage', label: 'Triage' },
          {
            name: 'consult_type',
            label: this.$t('custom.uhb.consult.consult-type')
          },
          { name: 'actions', label: '', align: 'right' }
        ],
        rows: [],
        total: 0,
        paginationTotal: 0
      },
      {
        label: this.$t('custom.uhb.consult.tab-reviewing') as string,
        statuses: ['Awaiting Review', 'Reviewing'],
        columns: [
          {
            name: 'consult_at',
            label: this.$t('custom.uhb.consult.consult-date')
          },
          { name: 'last_name', label: this.$t('platform.patient.patient') },
          { name: 'patient_ids' },
          { name: 'clinic_name', label: this.$t('custom.uhb.consult.clinic') },
          { name: 'location', label: this.$t('custom.uhb.consult.site') },
          { name: 'triage', label: 'Triage' },
          { name: 'reviewer', label: 'Reviewer' },
          { name: 'actions', label: '', align: 'right' }
        ],
        rows: [],
        total: 0,
        paginationTotal: 0
      },
      {
        label: this.$t('custom.uhb.consult.tab-rejected') as string,
        statuses: ['Rejected'],
        columns: [
          {
            name: 'rejected_at',
            label: this.$t('custom.uhb.consult.rejected-date')
          },
          { name: 'last_name', label: this.$t('platform.patient.patient') },
          { name: 'patient_ids' },
          { name: 'clinic_name', label: this.$t('custom.uhb.consult.clinic') },
          { name: 'location', label: this.$t('custom.uhb.consult.site') },
          {
            name: 'rejected_reason_type',
            label: this.$t('custom.uhb.consult.reason')
          },
          { name: 'actions', label: '', align: 'right' }
        ],
        rows: [],
        total: 0,
        paginationTotal: 0
      }
    ];
  }

  async mounted() {
    this.search = String(this.$route.query['filter[search]'] || '');
    this.columns = this.status[this.selectedStatusIndex].columns;

    await this.fetchConsults();

    try {
      this.statuses =
        await this.ophthalmologyConsultService.fetchCodeableConceptsByCategory(
          codeableConceptCategories.encounterStatus
        );
      this.encounterClasses =
        await this.ophthalmologyConsultService.fetchCodeableConceptsByCategory(
          codeableConceptCategories.encounterClass
        );
    } catch (error) {
      if (!axios.isCancel(error)) {
        this.progressStore.setError();
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.fetch-error')
        });
      }
    }

    if (isFeatureFlagEnabled(FEATURES.ORGANISATION_LOCATIONS)) {
      this.fetchLocations();
    }
    this.allConsultsFirstLoadingIsDone = true;

    this.unWatchRoute = this.$watch('$route', async (to, from) => {
      if (from.path === to.path && from.query !== to.query) {
        await this.fetchConsults();
        window.scroll({
          top: 0,
          behavior: 'smooth'
        });
      }
    });

    // Re-fetch consults and location when switching organisation
    this.$watch('organisationId', async () => {
      this.search = '';
      if (Object.keys(this.$route.query).length) {
        await this.$router.replace({ path: this.$route.path });
      }
      await this.fetchConsults();

      if (isFeatureFlagEnabled(FEATURES.ORGANISATION_LOCATIONS)) {
        await this.fetchLocations();
      }
    });
  }

  unmounted() {
    if (this.unWatchRoute) {
      this.unWatchRoute();
    }
    if (this.request) {
      this.request.cancel();
    }
  }

  async fetchLocations() {
    try {
      this.locations = (await this.locationService.index()).data;
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.consult.fetch-locations-error')
      });
    }
  }

  formatDob(isoDate: string) {
    return isoDate ? this.$d(getDobFromISOString(isoDate), 'shortMonth') : '';
  }

  formatFromNow(dob: string) {
    return dayjs(dob).fromNow();
  }

  getSexOrGender(patient: Patient): string | TranslateResult | undefined {
    if (this.sessionStore.currentOrganisation?.uses_gender && patient.gender) {
      const gender = this.genderOptions.find(
        (option) => option.value === patient.gender
      );
      return gender ? gender.label : patient.gender;
    }
    const sex = this.sexOptions.find((option) => option.value === patient.sex);
    return sex ? sex.label : patient.sex;
  }

  async setSelectedStatusIndex(index: number) {
    if (this.allConsultsFirstLoadingIsDone) {
      this.selectedStatusIndex = index;
      this.emptyDatatable();
      const queryParams = this.$route.query;
      this.columns = this.status[this.selectedStatusIndex].columns;
      // eslint-disable-next-line no-negated-condition
      if (Object.keys(queryParams).length === 0) {
        await this.fetchConsults();
        window.scroll({
          top: 0,
          behavior: 'smooth'
        });
      } else {
        this.search = '';
        await this.$router.replace({ path: this.$route.path });
      }
    }
  }

  emptyDatatable() {
    this.status.map((status) => (status.rows = []));
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
  }

  async applyFilters(selectedFilters: IFilterOptionsSelected) {
    this.filteredLocations = selectedFilters.checkboxItems;
    await this.fetchConsults();
  }

  async updateSort(sort: string) {
    // Keep the search if present, but always reset the page
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...(this.search ? { search: this.search } : {}),
        sort
      }
    });
  }

  async updateSearch() {
    // Maintain sort order and only add search param when non-empty
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...(this.$route.query.sort ? { sort: this.$route.query.sort } : {}),
        ...(this.search ? { 'filter[search]': this.search } : {})
      }
    });
  }

  async changePage(page: number) {
    // Keep all existing query parameters
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        page: page.toString()
      }
    });
  }

  extractEpisodeOfCareFromConsult(
    id: string
  ): EpisodeOfCareResponseData | undefined {
    return this.findOphthalmologyConsultById(id)?.episodeOfCare;
  }

  findOphthalmologyConsultById(
    id: string
  ): OphthalmologyConsultViewModel | undefined {
    return this.ophthalmologyConsults.find(
      (consult: OphthalmologyConsultViewModel) =>
        id === consult.episodeOfCare.id
    );
  }

  async fetchConsults() {
    const targetSelectedIndex = this.selectedStatusIndex;
    this.loading = true;

    const extraParams: RequestParams = {
      include: 'ophthalmologyEpisodeOfCareDetail,media,patient',
      page: this.page,
      sort: this.sort,
      ...(this.search ? { 'filter[search]': this.search } : {}),
      ...{
        'filter[status]': this.selectedStatus,
        'filter[ownerOrProvider]': this.organisationId,
        'filter[location]': this.filteredLocations?.join(',')
      }
    };

    try {
      const response = await this.ophthalmologyConsultService.fetchAll(
        this.organisationId,
        {
          extraParams
        }
      );

      this.ophthalmologyConsults = response.data;

      // @ts-ignore
      // FIXME: fix the type as it's clearly not a consult
      this.status[targetSelectedIndex].rows = response.data.map(
        (consult: OphthalmologyConsultViewModel) => ({
          id: consult.episodeOfCare.id,
          created_at: consult.createdDate,
          ...(consult.hasConsultDate
            ? { consult_at: consult.consultDate }
            : {}),
          ...(consult.ambulatoryEncounterIsRejected
            ? { rejected_at: consult.ambulatoryEncounterRejectedDate }
            : {}),
          ...(consult.hasScheduledDate
            ? { scheduled_at: consult.scheduledDate }
            : {}),
          ...(consult.hasTriageStatus
            ? { triage_status: consult.triageStatus }
            : {}),
          ...(consult.hasRejectedReasonType
            ? { rejected_reason_type: consult.rejectedReasonType }
            : {}),
          ...(consult.ambulatoryEncounter
            ? {
              form_submission: {
                id: consult.ambulatoryEncounterFormSubmissionId
              }
            }
            : {}),
          last_name: consult.patient.last_name,
          patient: {
            ...consult.patient,
            mrn: consult.patientMrn,
            nhs: getNhsNumberFromPatient(consult.patient)
          },
          clinic: consult.clinic,
          location: consult.hasLocation ? consult.location : '',
          status: consult.status,
          consult_type: consult.consultType,
          assigned_reviewer_user_name: consult.hasReviewer
            ? consult.reviewer.name
            : '',
          isMuted: consult.patientIsDeceased,
          consultationId: consult.ambulatoryEncounter?.id ?? null,
          review: {
            ...consult.ophthalmologyDetails,
            id: consult.virtualEncounter?.id,
            locked: consult.isLocked
          }
        })
      );

      this.perPage = response.meta.per_page;
      if (!this.search) {
        this.status[targetSelectedIndex].total = response.meta.total;
      }

      this.status[targetSelectedIndex].paginationTotal = response.meta.total;
      this.applyStatusCounts(response.meta.status_counts);
      this.progressStore.finishProgress();
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.progressStore.setError();
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.fetch-error')
        });
      }
      if (!axios.isAxiosError(err)) {
        throw err;
      }
    } finally {
      if (targetSelectedIndex === this.selectedStatusIndex) {
        this.loading = false;
      }
    }
  }

  applyStatusCounts(statusCounts: EpisodeOfCareStatusCounts): void {
    this.status.forEach((status: ConsultStatusSelectorOption) => {
      if (status.label === this.$t('custom.uhb.consult.tab-all-consults')) {
        status.total = statusCounts.All;
        return;
      }
      status.total = this.getTotalForStatuses(status.statuses, statusCounts);
    });
  }

  getTotalForStatuses(
    statuses: Status[],
    statusCounts: EpisodeOfCareStatusCounts
  ): number {
    return statuses.reduce(
      (total: number, status: Status) => total + statusCounts[status],
      0
    );
  }

  getStatusIndexByName(name: string) {
    return this.status.findIndex((item) => item.label === name);
  }

  // Schedule Consult
  showScheduleConsultModal(consult: Partial<Consult>) {
    this.selectedConsultId = consult.id || '';
    this.currentConsult = consult;
    this.selectedPatient = consult.patient ? consult.patient : null;
    this.scheduleConsultModalVisible = true;
  }

  closeScheduleConsultModal() {
    this.selectedConsultId = '';
    this.scheduleConsultModalVisible = false;
  }

  async updateAfterScheduled() {
    await this.fetchConsults();
  }

  async schedule(scheduleDetails: ScheduleValue) {
    const scheduledStatus = findCodeableConceptByCode('triaged', this.statuses);
    try {
      const data = {
        status_id: scheduledStatus?.id ?? '',
        scheduled_at: scheduleDetails.scheduled_at,
        scheduled_location: scheduleDetails.scheduled_location,
        ...(scheduleDetails.patient_mrn_at_clinic_provider && {
          patient_mrn_at_clinic_provider:
            scheduleDetails.patient_mrn_at_clinic_provider
        })
      };

      const currentEpisodeOfCare = this.extractEpisodeOfCareFromConsult(
        this.currentConsult?.id
      );

      if (currentEpisodeOfCare?.encounters[0]) {
        await this.encounterService.update(
          currentEpisodeOfCare.encounters[0].id,
          data
        );
        // this.$emit('update-patient');
      }
      this.closeScheduleConsultModal();
      this.fetchConsults();
    } catch (e) {
      if (e.response?.status === 422) {
        this.scheduleErrors = e.response.data?.errors;
      } else {
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.update-error')
        });
      }
    }
  }

  // Start a consult
  async startConsult(consult: Consult) {
    this.loadingAction = true;
    const currentEpisodeOfCare = this.extractEpisodeOfCareFromConsult(
      consult.id
    );

    try {
      const formSubmissionService = new PatientFormSubmissionService(
        consult.patient.id
      );

      const response = await formSubmissionService.create({
        form_id: currentEpisodeOfCare.ophthalmology_details.form_id,
        organisational_unit_id: this.organisationId,
        encounter_id: currentEpisodeOfCare?.encounters[0].id
      });
      if (currentEpisodeOfCare) {
        const inProgressStatus = findCodeableConceptByCode(
          'in-progress',
          this.statuses
        );

        await this.encounterService.update(
          currentEpisodeOfCare.encounters[0].id,
          {
            status_id: inProgressStatus?.id || ''
          }
        );
      }
      await this.$router.push({
        name: 'patient-form',
        params: {
          organisationId: this.organisationId,
          patientId: consult.patient.id,
          formSubmissionId: response.data.id
        }
      });
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: `${this.$t('platform.error.error')}: ${e.message ?? ''}`
      });
      this.loadingAction = false;
    }
  }

  // Resume a consult
  resumeConsult(consult: Consult) {
    if (consult.form_submission && consult.patient) {
      this.$router.push({
        name: 'patient-form',
        params: {
          organisationId: this.organisationId,
          patientId: consult.patient.id,
          formSubmissionId: consult.form_submission.id
        }
      });
    }
  }

  async startReview(consult: Consult) {
    if (consult.review?.id) {
      await this.resumeReview(consult.review?.id);
    } else {
      await this.createReview(consult);
    }

    await this.$router.push({
      name: 'patient-image-review',
      params: {
        organisationId: this.organisationId,
        patientId: consult.patient.id,
        episodeOfCareId: consult.id
      }
    });
  }

  async resumeReview(encounterId: string) {
    const participantTypes =
      await this.ophthalmologyConsultService.fetchCodeableConceptsByCategory(
        codeableConceptCategories.participantType
      );

    const createdStatus = findCodeableConceptByCode(
      'in-progress',
      this.statuses
    );

    const reviewParticipantType = findCodeableConceptByCode(
      'REV',
      participantTypes
    );

    const data = {
      status_id: createdStatus?.id,
      locked: true,
      participants: [
        {
          user_id: this.currentUserId,
          participant_type_id: reviewParticipantType?.id,
          participant_type_code: reviewParticipantType?.code
        }
      ]
    };

    try {
      return await this.encounterService.update(encounterId, data);
    } catch (error) {
      if (error.response?.status === 422) {
        this.errors = error.response?.data?.errors;
      } else {
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.update-error'),
          label: error.response?.data?.message || ''
        });
      }
    }
    return;
  }

  async createReview(consult: Consult) {
    const createdStatus = findCodeableConceptByCode(
      'in-progress',
      this.statuses
    );
    const encounterClass = findCodeableConceptByCode(
      'VR',
      this.encounterClasses
    );

    const data = {
      patient_id: consult.patient?.id,
      encounter_class_id: encounterClass?.id,
      episode_of_care_id: consult.id,
      clinic_id: consult.clinic?.id,
      organisational_unit_id: this.organisationId,
      status_id: createdStatus?.id,
      locked: true,
      part_of: consult.consultationId
    };

    try {
      const virtualEncounter = await this.encounterService.create(data);
      return virtualEncounter.data;
    } catch (error) {
      if (error.response?.status === 422) {
        this.errors = error.response?.data?.errors;
      } else {
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.create-error'),
          label: error.response?.data?.message || ''
        });
      }
    }
    return;
  }

  async continueConsult(
    patientId: string,
    consultId: string,
    encounterId: string
  ) {
    await this.encounterService.update(encounterId, { locked: false });

    return this.$router.push({
      name: 'patient-image-review',
      params: {
        organisationId: this.organisationId,
        patientId,
        episodeOfCareId: consultId
      }
    });
  }

  openMoveBackToScheduledModal(consult: Partial<Consult>) {
    this.selectedConsultId = consult.id || '';
    this.currentConsult = consult;
    this.selectedPatient = consult.patient ? consult.patient : null;
    this.enableMoveBackToScheduledModal = true;
  }

  closeMoveBackToScheduledModal() {
    this.selectedConsultId = '';
    this.currentConsult = null;
    this.selectedPatient = null;
    this.enableMoveBackToScheduledModal = false;
    this.fetchConsults();
  }

  async moveBackToScheduled() {
    const triagedStatus = findCodeableConceptByCode('triaged', this.statuses);
    try {
      const data = {
        status_id: triagedStatus?.id ?? ''
      };

      const currentEpisodeOfCare = this.extractEpisodeOfCareFromConsult(
        this.currentConsult?.id
      );

      if (currentEpisodeOfCare?.encounters[0]) {
        const formSubmissionService = new PatientFormSubmissionService(
          this.currentConsult.patient.id
        );

        await formSubmissionService.delete(
          this.currentConsult.form_submission.id
        );
        await this.encounterService.update(
          currentEpisodeOfCare.encounters[0].id,
          data
        );

        this.notificationStore.snackbar = {
          label: this.$t(
            'custom.uhb.consult.move-back-to-scheduled.snackbar-message'
          )
        };
      }
    } catch (e: any) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.consult.update-error')
      });
    } finally {
      this.closeMoveBackToScheduledModal();
    }
  }

  // Reject consult
  openRejectReasonModal(consult: Partial<Consult>) {
    this.selectedConsultId = consult.id || '';
    this.currentConsult = consult;
    this.selectedPatient = consult.patient ? consult.patient : null;
    this.enableRejectReasonModal = true;
  }

  closeRejectReasonModal() {
    this.selectedConsultId = '';
    this.currentConsult = null;
    this.selectedPatient = null;
    this.enableRejectReasonModal = false;
    this.fetchConsults();
  }

  closeResolveReasonModal() {
    this.selectedConsultId = '';
    this.currentConsult = null;
    this.selectedPatient = null;
    this.enableResolveReasonModal = false;
    this.fetchConsults();
  }

  async markAsRejected(rejectedReason: RejectReasonValue) {
    const rejectedStatus = findCodeableConceptByCode(
      'cancelled',
      this.statuses
    );
    try {
      const data = {
        status_id: rejectedStatus?.id ?? '',
        extension: {
          rejected: {
            type: rejectedReason.selectedReason,
            reason: rejectedReason.additionalReason
          }
        }
      };

      const currentEpisodeOfCare = this.extractEpisodeOfCareFromConsult(
        this.currentConsult?.id
      );

      if (currentEpisodeOfCare?.encounters[0]) {
        await this.encounterService.update(
          currentEpisodeOfCare.encounters[0].id,
          data
        );
        await this.fetchConsults();
      }
      this.closeRejectReasonModal();
    } catch (e) {
      if (e.response?.status === 422) {
        this.rejectReasonError = e.response.data?.message ?? '';
      } else {
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.update-error')
        });
      }
    }
  }

  updateRejectErrorMessage(value: string) {
    this.rejectReasonError = value;
  }

  updateResolveErrorMessage(value: string) {
    this.resolveReasonError = value;
  }

  extractRejectedErrorMessageFromResponseErrors(
    errors: IErrors
  ): string | null {
    let errorMessages: Array<string> = [];
    if (errors.rejected_reason) {
      errorMessages = errorMessages.concat(errors.rejected_reason);
    }
    if (errors.rejected_reason_type) {
      errorMessages = errorMessages.concat(errors.rejected_reason_type);
    }
    if (errorMessages.length === 0) {
      return null;
    }
    return errorMessages.join(' ');
  }

  extractResolvedErrorMessageFromResponseErrors(
    errors: IErrors
  ): string | null {
    let errorMessages: Array<string> = [];
    if (errors.resolved_reason) {
      errorMessages = errorMessages.concat(errors.resolved_reason);
    }
    if (errors.resolved_reason_type) {
      errorMessages = errorMessages.concat(errors.resolved_reason_type);
    }
    if (errorMessages.length === 0) {
      return null;
    }
    return errorMessages.join(' ');
  }

  // Resolve consult
  showResolveModal(consult: Consult) {
    this.selectedConsultId = consult.id;
    this.currentConsult = consult;
    this.selectedPatient = consult.patient ? consult.patient : null;
    this.enableResolveReasonModal = true;
  }

  closeResolveModal() {
    this.selectedConsultId = '';
    this.enableResolveReasonModal = false;
    this.fetchConsults();
  }

  async markAsResolved(resolveReason: ResolveReasonValue) {
    const resolvedStatus = findCodeableConceptByCode(
      'resolved-rejected',
      this.statuses
    );
    try {
      const data = {
        status_id: resolvedStatus?.id ?? '',
        extension: {
          resolved: {
            type: resolveReason.selectedReason,
            reason: resolveReason.additionalReason
          }
        }
      };
      const currentEpisodeOfCare = this.extractEpisodeOfCareFromConsult(
        this.currentConsult?.id
      );

      if (currentEpisodeOfCare?.encounters[0]) {
        await this.encounterService.update(
          currentEpisodeOfCare.encounters[0].id,
          data
        );
        await this.fetchConsults();
      }
      this.closeResolveReasonModal();
    } catch (e) {
      if (e.response?.status === 422) {
        this.resolveReasonError = e.response.data?.message ?? '';
      } else {
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.update-error')
        });
      }
    }
  }

  // Lock/unlock review
  clickUnlockConsult(
    patient: Patient,
    reviewId: string,
    lockedByUserFullName: string
  ) {
    this.selectedPatient = patient;
    this.modalTargetReviewId = reviewId;
    this.modalTargetLockedByUserFullName = lockedByUserFullName;
    this.enableLockConsultModal = true;
  }

  closeUnlockModal() {
    this.modalTargetReviewId = '';
    this.modalTargetLockedByUserFullName = '';
    this.enableLockConsultModal = false;
  }

  async unlockConsult(encounterId: string) {
    await this.encounterService.update(encounterId, { locked: false });
    this.closeUnlockModal();
    return this.fetchConsults();
  }

  // View Patient Review Summary
  openViewAndCopyReviewSummaryModal(consult: Consult) {
    this.viewAndCopyReviewSummary = true;
    this.currentConsult = consult;
  }

  closeViewAndCopyReviewSummaryModal() {
    this.viewAndCopyReviewSummary = false;
    this.currentConsult = null;
  }
}
